import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {createSearchParams, Link} from 'react-router-dom'
import {Text, Flexed, Spacer, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import Button from './Button'
import {switchTheme, loginUserDetailsAction, loginBusinessDetailsAction, isImageSearch, saveSearchImage} from '../../actions/authActions'
import {FaSun, FaMoon, FaBars} from 'react-icons/fa'
import {BsXLg} from 'react-icons/bs'
import {MdOutlineNotifications} from 'react-icons/md'
import {FaAngleDown} from 'react-icons/fa'
import {api, doGetCustomerProfile, doGetOwnerProfile} from '../api/callAxios'
import {toast} from 'react-toastify'
import InputField from './InputField'
import LocationSearch from './LocationSearch'
import useWindowSize from '../../hooks/useWindowSize'
import Pusher from 'pusher-js'
import {RiLogoutCircleLine, RiProfileLine} from 'react-icons/ri'
import {RiSettings5Line} from 'react-icons/ri'
import moment from 'moment'
import {AiOutlineHeart} from 'react-icons/ai'
import {LiaThListSolid} from 'react-icons/lia'
import {useSearchParams} from 'react-router-dom'
import {doGenerateAiResponse} from '../api/AI_Api'
import {verifyJSON} from '../../constants/commonFunctions'
import {Loading} from '../../styled/shared'
import VoiceRecognition from './VoiceRecognition'

interface IProps {
	scroll: number
	path?: boolean
	active?: boolean
	isDarkTheme?: boolean
}

const Header = ({sideBarRefresh, searchKeyword, searchLocation, setSearchLocation, setSearchKeyword, appSearch, clearFilter, onChangeBusinessType}: any) => {
	const {pathname} = useLocation()
	const windowSize = useWindowSize()
	const _ref: any = useRef(null)
	let _navigate = useNavigate()
	let _dispatch = useDispatch()
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _search: any = useSelector<any>((state: any) => state.auth.search)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
	const _authToken: any = localStorage.getItem('authorization')
	const _loginUserStatus: any = localStorage.getItem('userStatus')
	const [searchParams] = useSearchParams()
	let _id: any = searchParams.get('id')
	const [typeId, setTypeId] = useState('')
	const [scrollPosition, setScrollPosition] = useState(0)
	const [navBarListOpen, setNavBarListOpen] = useState(false)
	const [offersMenu, setOffersMenu] = useState([])
	const [notifications, setNotifications] = useState([])
	const [notificationsCounts, setNotificationsCounts] = useState(0)
	const [openNotificationList, setOpenNotificationList] = useState(false)
	const fileInputRef: any = useRef(null)
	const [query, setQuery] = useState<any>()
	const [isLoading, setIsLoading] = useState(false)

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		getBusinessTypes()
		window.addEventListener('scroll', handleScroll, {passive: true})

		const handleClickOutside = (event: any) => {
			if (_ref?.current && !_ref?.current?.contains(event.target)) {
				setOpenNotificationList(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const getProfile = async (status: string) => {
		if (status === 'owner') {
			let response = await doGetOwnerProfile()
			if (response) {
				_dispatch(loginBusinessDetailsAction(response?.userBusiness))
				_dispatch(loginUserDetailsAction(response?.user))
			}
		} else if (status === 'customer') {
			let response = await doGetCustomerProfile()
			if (response) {
				_dispatch(loginUserDetailsAction(response))
			}
		}
	}

	useEffect(() => {
		if (_authToken) {
			getProfile(_loginUserStatus)
		}
	}, [_authToken])

	useEffect(() => {
		if (_id) {
			setTypeId(_id)
		}
	}, [_id])

	const getBusinessTypes = () => {
		api.get(`/business-type`)
			.then((res) => {
				if (res.data) {
					let storeType: any = []
					res?.data?.businesTypes?.map((e: any) => {
						storeType.push({title: e?.title, id: e.id})
					})
					setOffersMenu(storeType)
				}
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
				}
			})
	}

	const getNotifications = async (_loginUserStatus: any) => {
		let _path = ''
		if (_loginUserStatus === 'owner') {
			_path = '/notification/business_notifications'
		} else {
			_path = '/notification/my_notifications'
		}
		await api
			.post(_path)
			.then((res) => {
				if (res?.data) {
					setNotifications(res?.data)
					getNotificationsCount(_loginUserStatus)
				}
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
				}
			})
	}

	const getNotificationsCount = (_loginUserStatus: any) => {
		let _path = ''
		if (_loginUserStatus === 'owner') {
			_path = '/notification/business_notification_count'
		} else {
			_path = '/notification/notification_count'
		}
		api.post(_path)
			.then((res) => {
				setNotificationsCounts(res?.data)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
				}
			})
	}

	const notificationsRead = (_loginUserStatus: any) => {
		let _path = ''
		if (_loginUserStatus === 'owner') {
			_path = '/notification/update_business_notification'
		} else {
			_path = '/notification/update_notification'
		}
		api.put(_path)
			.then((res) => {
				getNotifications(_loginUserStatus)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
				}
			})
	}
	useEffect(() => {
		if (_search != null && _search != '') {
			setSearchKeyword(_search)
			setQuery(_search)
			appSearch(_search, searchLocation)
		}
	}, [_search])

	useEffect(() => {
		const pusher = new Pusher('780302372e2798b5baf6', {
			cluster: 'ap2'
		})
		const channel = pusher.subscribe('my-channel')
		channel.bind('my-event', function (data: any) {
			const _authToken: any = localStorage.getItem('authorization')
			const _loginUserStatus: any = localStorage.getItem('userStatus')
			if (_authToken) {
				getNotifications(_loginUserStatus)
			}
		})
		return () => {
			channel.unbind_all()
			channel.unsubscribe()
		}
	}, [])

	const generateImageAiResponse = async (images?: any) => {
		try {
			setIsLoading(true)
			const _text = `Parse the image and generate a relevant response. Use title to store image name. Do not use contact information in the description. Provide a description with a maximum of 4029 words, and always use numerical values for the price. Verify and respond with all possible details, returning responses in all relevant fields. If fields are not applicable to the business type and category, return null or an empty string. The result should be a single object without any extra text.`

			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				console.log('=====res', res)
				_dispatch(isImageSearch(true))
				_dispatch(saveSearchImage(images))

				let data = verifyJSON(res) ? JSON.parse(res) : {}
				if (!data?.title || !data?.name) {
					const stringWithEmbeddedJSON = res

					const jsonMatch = stringWithEmbeddedJSON.match(/```json([\s\S]*?)```/)

					if (jsonMatch && jsonMatch[1]) {
						const extractedJSON = jsonMatch[1].trim()

						try {
							const parsedObject: any = JSON.parse(extractedJSON)
							data = parsedObject
							console.log('parsedObject:', parsedObject, data)
						} catch (error) {
							console.error('Error parsing JSON:', error)
						}
					} else {
						console.error('No JSON found in the string.')
					}
				}

				if (data?.title || data?.name) {
					appSearch(data?.name ? data?.name : data?.title, searchLocation)
					setSearchKeyword(data?.name ? data?.name : data?.title)
					setQuery(data?.name ? data?.name : data?.title)
				}
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}

	const handleLinkClick = () => {
		// Trigger the file input when the link is clicked
		if (fileInputRef?.current) {
			fileInputRef.current.click()
		}
	}

	const handleFileChange = async (event: any) => {
		if (event.target.files?.length) {
			let res = await fileToGenerativePart(event.target.files[0])
			_dispatch(isImageSearch(false))
			_dispatch(saveSearchImage(''))
			if (res) {
				generateImageAiResponse([res])
			}
		}
	}

	const fileToGenerativePart = (file: any) => {
		return new Promise((resolve: any, reject: any) => {
			const reader: any = new FileReader()

			reader.onloadend = () => {
				const data = reader.result.split(',')[1] // Extract base64 data
				const mimeType = file.type

				resolve({
					inlineData: {
						data,
						mimeType
					}
				})
			}

			reader.onerror = reject
			reader.readAsDataURL(file)
		})
	}

	return (
		<Main scroll={scrollPosition} path={pathname === '/'} isDarkTheme={_isDarkTheme}>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			<Container>
				<Row>
					<CustomCol>
						<Link to="/" onClick={clearFilter}>
							<Logo src="/images/Logo.png" alt="logo" />
						</Link>

						<ActionMenu direction="row" align="center">
							<MenuList
								type="normal"
								isDarkTheme={_isDarkTheme}
								scroll={scrollPosition}
								path={pathname === '/demo'}
								onClick={() => {
									_navigate('/demo')
								}}>
								Demo
							</MenuList>
							<MenuList type="normal" isDarkTheme={_isDarkTheme} scroll={scrollPosition} path={pathname === '/'}>
								{/* onClick={()=>{clearFilter()}} */}
								{windowSize?.width < 576 ? 'Menu' : 'We offers'} <Arrow isDarkTheme={_isDarkTheme} />
								<DropContent width="12rem" isDarkTheme={_isDarkTheme}>
									{offersMenu.length
										? offersMenu.map((e: any) => {
												return (
													<DropMenu
														isDarkTheme={_isDarkTheme}
														href="#"
														onClick={() => {
															onChangeBusinessType()
															_navigate({
																pathname: '/business',
																search: createSearchParams({
																	id: e.id
																}).toString()
															})
														}}>
														{e.title}
													</DropMenu>
												)
										  })
										: 'No Offers to show'}
								</DropContent>
							</MenuList>
							{_isDarkTheme ? (
								<DarkMode
									fill={`${palette.white}`}
									onClick={() => {
										_dispatch(switchTheme(false))
									}}
								/>
							) : (
								<LightMode
									fill={`${palette.golden}`}
									onClick={() => {
										_dispatch(switchTheme(true))
									}}
								/>
							)}

							{_authToken ? (
								<>
									<NotificationMenu
										isDarkTheme={_isDarkTheme}
										active={openNotificationList}
										ref={_ref}
										onClick={() => {
											setOpenNotificationList(!openNotificationList)
											const _loginUserStatus: any = localStorage.getItem('userStatus')
											notificationsRead(_loginUserStatus)
										}}>
										{notificationsCounts > 0 && (
											<NotificationsCounts direction="row" align="center" justify="center">
												{notificationsCounts > 9 ? '9+' : notificationsCounts}
											</NotificationsCounts>
										)}
										<MdOutlineNotifications fontSize={windowSize?.width < 575 ? '1.4rem' : '1.5rem'} />
										{openNotificationList && (
											<DropContent id="notificationMenuContent" isDarkTheme={_isDarkTheme}>
												{notifications?.map((val: any, index: any) => {
													return (
														<NotificationContent
															active={!val?.isRead}
															key={index + 'notification'}
															direction="row"
															gap={1}
															align="center"
															isDarkTheme={_isDarkTheme}
															onClick={() => {
																if (val?.OrderId) {
																	_navigate({
																		pathname: '/order-details',
																		search: createSearchParams({
																			id: val?.OrderId
																		}).toString()
																	})
																} else {
																	if (_loginUserStatus === 'customer') {
																		_navigate('/customer-profile')
																	} else {
																		_navigate({
																			pathname: '/business-details',
																			search: createSearchParams({
																				id: _loginBusinessDetails?.id
																			}).toString()
																		})
																	}
																}
															}}>
															{val?.image && (
																<div>
																	<ProfileImg>
																		<img src={process.env.REACT_APP_IMAGE_URL + val?.image} alt="i" />
																	</ProfileImg>
																</div>
															)}
															<div>
																<Text fontWeight="bold" type="small" margin="0rem 0rem 0.5rem 0rem" isDarkTheme={_isDarkTheme}>
																	{val?.body}
																</Text>
																<Text isDarkTheme={_isDarkTheme} type="xsmall">
																	{moment(val?.createdAt).fromNow(true)} ago{' '}
																</Text>
															</div>
														</NotificationContent>
													)
												})}
												{notifications?.length === 0 && (
													<NotificationContent direction="row" gap={1} align="center" justify="center" isDarkTheme={_isDarkTheme}>
														<Text isCentered isDarkTheme={_isDarkTheme} type="xsmall">
															You haven't received any notifications yet.
														</Text>
													</NotificationContent>
												)}
											</DropContent>
										)}
									</NotificationMenu>
									<MenuListProfile
										id="lll"
										type="normal"
										active={pathname === '/'}
										isDarkTheme={_isDarkTheme}
										scroll={scrollPosition}
										path={pathname === '/'}
										onMouseEnter={() => {
											setOpenNotificationList(false)
										}}>
										<UserProfile direction="row" align="center" gap={0.5} isDarkTheme={_isDarkTheme}>
											<ProfileImg>
												{_loginUserStatus === 'owner' ? (
													<>
														{_loginBusinessDetails?.photo ? (
															<img src={process.env.REACT_APP_IMAGE_URL + _loginBusinessDetails?.photo} alt="i" />
														) : (
															<Text color="white" fontWeight="bold" type="small">
																{_loginBusinessDetails?.name && _loginBusinessDetails?.name[0].toUpperCase()}
															</Text>
														)}
													</>
												) : (
													<>
														{_loginUserDetails?.photo ? (
															<img src={process.env.REACT_APP_IMAGE_URL + _loginUserDetails?.photo} alt="i" />
														) : (
															<Text color="white" fontWeight="bold" type="small">
																{_loginUserDetails?.firstname && _loginUserDetails?.firstname[0].toUpperCase()}
															</Text>
														)}
													</>
												)}
											</ProfileImg>
											<Arrow />
											<DropContent isDarkTheme={_isDarkTheme} right={'0'} top={3.5}>
												<ProfileMenuHeader>
													<ProfileImg>
														{_loginUserStatus === 'owner' ? (
															<>
																{_loginBusinessDetails?.photo ? (
																	<img src={process.env.REACT_APP_IMAGE_URL + _loginBusinessDetails?.photo} alt="i" />
																) : (
																	<Text color="white" fontWeight="bold" type="small">
																		{_loginBusinessDetails?.name && _loginBusinessDetails?.name[0].toUpperCase()}
																	</Text>
																)}
															</>
														) : (
															<>
																{_loginUserDetails?.photo ? (
																	<img src={process.env.REACT_APP_IMAGE_URL + _loginUserDetails?.photo} alt="i" />
																) : (
																	<Text color="white" fontWeight="bold" type="small">
																		{_loginUserDetails?.firstname && _loginUserDetails?.firstname[0].toUpperCase()}
																	</Text>
																)}
															</>
														)}
													</ProfileImg>
													<div>
														<Text type="small" isDarkTheme={_isDarkTheme}>
															Hello
														</Text>
														<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
															{_loginUserStatus === 'owner' ? _loginBusinessDetails?.name : _loginUserDetails?.firstname}
														</Text>
														<Text
															type="xsmall"
															fontWeight="bold"
															isDarkTheme={_isDarkTheme}
															onClick={() => {
																if (_loginBusinessDetails?.id) {
																	_navigate({
																		pathname: '/business-details',
																		search: createSearchParams({
																			id: _loginBusinessDetails.id
																		}).toString()
																	})
																} else {
																	_navigate('/customer-profile')
																}
															}}>
															View & edit your Profile
														</Text>
													</div>
												</ProfileMenuHeader>
												<Divider isDarkTheme={_isDarkTheme} />
												<ProfileMenuList
													isDarkTheme={_isDarkTheme}
													onClick={() => {
														_navigate('/history')
													}}>
													<LiaThListSolid fontSize="1rem" />
													History
												</ProfileMenuList>
												<Divider isDarkTheme={_isDarkTheme} />
												<ProfileMenuList
													isDarkTheme={_isDarkTheme}
													onClick={() => {
														if (_loginBusinessDetails?.id) {
															_navigate('/business-settings')
														} else {
															_navigate('/customer-settings')
														}
													}}>
													<RiSettings5Line fontSize="1rem" />
													Settings
												</ProfileMenuList>
												<Divider isDarkTheme={_isDarkTheme} />
												<ProfileMenuList
													isDarkTheme={_isDarkTheme}
													onClick={() => {
														localStorage.removeItem('authorization')
														localStorage.removeItem('userStatus')
														_dispatch(loginUserDetailsAction({}))
														_dispatch(loginBusinessDetailsAction({}))
														_navigate('/')
													}}>
													<RiLogoutCircleLine fontSize="1rem" />
													Logout
												</ProfileMenuList>
											</DropContent>
										</UserProfile>
									</MenuListProfile>
								</>
							) : (
								<AuthFlex direction="row" gap={0.5}>
									<Button
										medium={windowSize?.width > 768}
										small={windowSize?.width < 768}
										label={windowSize?.width < 768 ? 'Business' : 'Become a partner'}
										type="red"
										ifClicked={() => {
											_navigate('/business-login')
										}}
									/>
									<Button
										medium={windowSize?.width > 768}
										small={windowSize?.width < 768}
										label={windowSize?.width < 768 ? 'Customer' : 'login & SignUp'}
										type="red"
										ifClicked={() => {
											_navigate('/customer-login')
										}}
									/>
								</AuthFlex>
							)}
						</ActionMenu>
					</CustomCol>
				</Row>
				{pathname != '/' && pathname != '/demo' && pathname != '/business-login' && pathname != '/business-signup' && pathname != '/business-forgotpassword' && pathname != '/customer-login' && pathname != '/customer-signup' && pathname != '/customer-forgotpassword' && pathname != '/place-order' && pathname != '/history' && pathname != '/order-details' && pathname != '/business-details' && pathname != '/customer-profile' && pathname != '/business-settings' && pathname != '/customer-settings' && (
					<Row justifyContent="center">
						{/* <SearchCol xl={4} lg={5} md={5.5} sm={6} xs={12}>
								<LocationSearch
									sideBarRefresh={sideBarRefresh}
									setSearchLocation={setSearchLocation}
									appSearch={(location: any) => {
										if (_id === typeId) {
											appSearch(searchKeyword, location)
										}
									}}
								/>
								<Spacer height={0.5} />
							</SearchCol>
							<Col xl={8} lg={7} md={6.5} sm={6} xs={12}>
								<InputField
									type="search"
									placeholder="Find Restaurants, Cafes and more..."
									value={searchKeyword}
									handleChange={(e: any) => {
										appSearch(e, searchLocation)
										setSearchKeyword(e)
									}}
								/>
								<Spacer height={0.5} />
							</Col> */}
						<>
							<SearchCol lg={4} md={4} sm={5} xs={12}>
								<LocationSearch
									setSearchLocation={setSearchLocation}
									appSearch={(location: any) => {
										appSearch(searchKeyword, location)
									}}
								/>
							</SearchCol>
							<Col lg={6.5} md={6.5} sm={5} xs={12}>
								<InputField
									type="search"
									placeholder="Find Cars, Mobile Phones and more..."
									value={query}
									handleChange={(e: any) => {
										if (e?.trimStart()?.length) {
											setQuery(e.trimStart())
										} else {
											setQuery(e)
											appSearch(e, searchLocation)
											setSearchKeyword(e)
										}

										_dispatch(isImageSearch(false))
									}}
									onKeyPress={(e: any) => {
										if (e?.key == 'Enter' && query) {
											appSearch(query, searchLocation)
											setSearchKeyword(query)
										}
									}}
								/>
							</Col>
							<Col lg={1.5} md={1.5} sm={1.5} xs={12} style={{display: 'flex', alignItems: 'center', padding: '0'}}>
								<VoiceRecognition
									setText={(e: any) => {
										setQuery(e)
										appSearch(e.trimStart(), searchLocation)
										setSearchKeyword(e.trimStart())
									}}
								/>
								<Col lg={8} md={8} sm={8} xs={12}>
									<Button
										label="Search"
										type="red"
										ifClicked={() => {
											if (query) {
												appSearch(query, searchLocation)
												setSearchKeyword(query)
											}
										}}
									/>
								</Col>
							</Col>
						</>

						<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', color: 'white'}}>
							<CustomLink onClick={handleLinkClick}>
								<Spacer height={0.5} /> Image Search
							</CustomLink>
							<input type="file" accept=".jpg, .png, .jpeg, .webp" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />
						</div>
					</Row>
				)}
			</Container>
		</Main>
	)
}

export const CustomLink = styled.div`
	color: black;
	margin: 0.5rem;
	margin-top: 0rem;
	cursor: pointer;
	color: ${palette.danger};
	font-weight: 500;
	font-size: 0.9rem;
	&:hover {
		color: blue;
	}
`

const Main = styled.div<IProps>`
	background: ${({scroll, path, isDarkTheme}) => (scroll < 0 && path ? 'transparent' : isDarkTheme ? `${palette.black}` : `#e8edf1`)};
	box-shadow: ${palette.shadow};
	position: sticky;
	top: 0;
	z-index: 5;
	width: 100%;
`

const CustomCol = styled(Col)`
	display: flex;
	height: 4.375rem;
	justify-content: space-between;
	align-items: center;
	${media.lg`padding:0 2rem;`};
`

const Logo = styled.img`
	height: 2.5rem;
	// ${media.sm`height:3.813rem`};
	// ${media.md`height:3.813rem`};
	${media.sm`height:3rem`};
	${media.md`height:3rem`};
`

const Menu = styled(Flexed)<IProps>`
	display: none;
	${media.lg`display:flex`};
`

const ActionMenu = styled(Flexed)`
	${media.xs`gap:0.7rem`};
	${media.sm`gap:0.7rem`};
	${media.md`gap:0.7rem`};
`

const DropContent = styled.div<any>`
	display: none;
	margin-top: 0.3rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: ${({width}) => (width ? width : 'max-content')};
	right: ${({right}) => (right ? `${right}rem` : 'auto')};
	top: ${({top}) => (top ? `${top}rem` : 'auto')};
	box-shadow: ${palette.shadow};
	z-index: 5;
	border-radius: 0.5rem;
	overflow: hidden;
`

const DropMenu = styled.a<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.875rem;
	padding: 0.425rem 1rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.red : palette.red)};
		color: ${palette.white};
	}
`

const Arrow = styled(FaAngleDown)<any>`
	transition: transform 0.2s;
	transform: rotate(0deg);
`

const MenuList = styled(Text)<IProps>`
	position: relative;
	color: ${({scroll, path, isDarkTheme}) => (isDarkTheme ? (path ? palette.red : palette.light_gray) : path ? palette.red : palette.dark_gray)};
	letter-spacing: 0.05em;
	font-weight: 600;
	cursor: pointer;
	// &:not(:last-child) {
	// 	padding-right: 2.5rem;
	// }
	&:after {
		content: '';
		// border-bottom: ${({active}) => (active ? ' 0.107rem' : `hidden`)} solid ${({scroll, path, isDarkTheme}) => (isDarkTheme ? `${palette.light_gray}` : scroll < 10 && path ? `${palette.white}` : `${palette.dark_gray}`)};
		display: block;
		margin: 0 auto;
		width: 70%;
		margin-top: 0.35rem;
	}
	& ${Arrow} {
		color: ${({scroll, path, isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : scroll < 0 && path ? `${palette.white}` : `${palette.gray}`)};
	}

	&:hover ${Arrow} {
		transform: rotate(-180deg);
	}
	&:hover ${DropContent} {
		display: block;
	}
	&:hover {
		color: ${palette.red};
	}
	${media.xs`font-size: 0.9rem`};
	${media.sm`font-size: 1rem`};
	${media.md`font-size: 1rem`};
`

const BarWrapper = styled.div<IProps>`
	color: ${({scroll, path, isDarkTheme}) => (isDarkTheme ? `${palette.light_gray}` : scroll < 0 && path ? `${palette.white}` : `${palette.dark_gray}`)};
	display: flex;
	${media.lg`display:none`};
	cursor: pointer;
`

const MenuListProfile = styled(MenuList)`
	padding-right: 0rem !important;
`

const DarkMode = styled(FaMoon)`
	font-size: 1.2rem;
	cursor: pointer;
	${media.sm`font-size: 1.4rem`};
	${media.md`font-size: 1.4rem`};
`

const LightMode = styled(FaSun)`
	font-size: 1.2rem;
	cursor: pointer;
	${media.sm`font-size: 1.4rem`};
	${media.md`font-size: 1.4rem`};
`

const SearchCol = styled(Col)<any>`
	${media.sm`padding-right:0rem;`};
	${media.md`padding-right:0rem;`};
`

const UserProfile = styled(Flexed)`
	cursor: pointer;
	padding: 0rem;
	${media.sm`padding: 0.5rem`};
	${media.md`padding: 0.5rem`};
	border-radius: 0.5rem;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.gray_300)};
	}
`

const ProfileImg = styled.div<any>`
	position: relative;
	width: 2.2rem;
	height: 2.2rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.red};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	& > img {
		width: 2.2rem;
		height: 2.2rem;
		border-radius: 3rem;
		object-fit: cover;
	}

	${media.sm`
	width: 2.5rem;
	height: 2.5rem;
	& > img {
		width: 2.5rem;
		height: 2.5rem;
	}
	`};
	${media.md`
	width: 2.5rem;
	height: 2.5rem;
	& > img {
		width: 2.5rem;
		height: 2.5rem;
	}`};
`

const ProfileMenuHeader = styled.div<any>`
	display: flex;
	gap: 1rem;
	padding: 0.8rem;
`

const ProfileMenuList = styled(Text)<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.9rem;
	padding: 0.8rem 1rem 0.8rem 0.8rem;
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: bold;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.red : palette.red)};
		color: ${palette.white};
	}
`

const NotificationMenu = styled.div<any>`
	position: relative;
	background-color: ${({active}) => (active ? palette.red_light : '')};
	color: ${({isDarkTheme, active}) => (isDarkTheme ? (active ? palette.black : palette.white) : palette.black)};
	&:hover {
		background-color: ${palette.red_light};
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.black)};
	}
	padding: 0.3rem;
	border-radius: 100%;
	cursor: pointer;
	& #notificationMenuContent {
		display: block !important;
		min-width: 20rem;
		right: -4rem;
		top: 3.1rem;
		max-height: 23rem;
		overflow: auto;
	}
	@media screen and (min-width: 0px) and (max-width: 575px) {
		& #notificationMenuContent {
			right: -2rem;
			min-width: 16rem;
		}
	}
`

const NotificationsCounts = styled(Flexed)`
	position: absolute;
	top: -1px;
	right: -1px;
	padding: 0.1rem;
	background: ${palette.red};
	color: ${palette.white};
	font-size: 0.7rem;
	font-weight: 600;
	width: 1.3rem;
	height: 1.3rem;
	border-radius: 100%;
`

const NotificationContent = styled(Flexed)<any>`
	position: relative;
	background-color: ${({active}) => (active ? '#074ed117' : '')};
	&:not(:last-child) {
		border-bottom: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	}
	&:hover {
		background-color: #074ed117;
	}
	padding: 1rem;
	cursor: pointer;
`

const AllAds = styled(Text)<any>`
	color: ${({active, isDarkTheme}) => (active ? palette.red : isDarkTheme ? palette.white : palette.black)};
	cursor: pointer;
	&:hover {
		color: ${palette.red_hover};
	}
	text-decoration: underline;
	text-underline-offset: 3px;
	${media.xs`font-size: 0.75rem`};
	${media.sm`font-size: 1rem`};
	${media.md`font-size: 1rem`};
`

const AuthFlex = styled(Flexed)`
	${media.xs`margin-left: 0.5rem`};
	& button {
		${media.xs`min-width: 5rem; padding:0rem;`}
		${media.sm`min-width: 6rem; padding:0.65rem 1rem;`}
	${media.md`min-width: 6rem; padding:0.65rem 1rem;`};
	}
`

export default Header
